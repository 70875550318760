// initial state
const state = () => ({
    error: null,
})

// getters
const getters = {
    error: (state) => {
        return state.error
    },
}

// actions
const actions = {
    setError ({ commit }, error) {
        commit('setError', error)
    },
}

// mutations
const mutations = {
    setError (state, error) {
        state.error = error;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}