<script setup>
import { ref } from "vue";
import store from "@/store";
import "../styles/components/receipt-card.css";

const receipt = store.getters["receipt/receipt"];
const showBarcodeValue = ref(false);
const visible = ref(false);

const receiptDate =
  new Date(receipt.receiptDetail.date).toLocaleDateString() +
  ", " +
  new Date(receipt.receiptDetail.date).toLocaleTimeString();

function openLink(url) {
  window.open(url, "_blank").focus();
}

function formatNumber(price) {
  // Convert the price to a string and round it to two decimal places
  const formattedPrice = parseFloat(price).toFixed(2);

  // Separate the whole and fractional parts
  const parts = formattedPrice.split(".");
  const wholePart = parts[0];
  const fractionalPart = parts[1];

  // Add thousand separators to the whole part if necessary
  const wholeWithCommas = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the whole and fractional parts with the pound sterling symbol
  return "£" + wholeWithCommas + "." + fractionalPart;
}
</script>

<template>
  <div class="receipt">
    <div class="store">
      <p class="primary-text">{{ receipt.receiptDetail.storeName }}</p>
      <p class="primary-text medium">
        {{ receipt.receiptDetail.retailerAddress }}
      </p>
      <p class="primary-text medium">
        {{ receipt.receiptDetail.retailerPostcode }}
      </p>
      <p class="grey-text">{{ receiptDate }}</p>
    </div>
    <div class="line-items">
      <div
        class="line-item"
        v-for="item in receipt.itemDetail"
        :key="item.rowKey"
      >
        <p :class="[item.returned ? 'returned' : '']">{{ item.name }}</p>
        <p :class="[item.returned ? 'returned' : '']">£{{ item.price }}</p>
      </div>
    </div>
    <hr class="keyline" />
    <div class="receipt-total">
      <p class="primary-text receipt-total-amount">Total</p>
      <p class="primary-text receipt-total-amount">
        £{{ receipt.receiptDetail.total }}
      </p>
    </div>
    <hr class="keyline" />

    <div v-if="receipt.discountDetail.length > 0" class="receipt-discount-info">
      <p>Discounts</p>
      <div
        class="receipt-discount"
        v-for="discount in receipt.discountDetail"
        :key="discount.rowKey"
      >
        <div class="payment-details">
          <p class="payment-detail">{{ discount.detail }}</p>
        </div>
        <p class="payment-amount">{{ formatNumber(discount.amount) }}</p>
      </div>
    </div>
    <hr class="keyline" v-if="receipt.discountDetail.length > 0" />
    <div class="receipt-payment-info">
      <p>Payment</p>
      <div
        class="receipt-payment"
        v-for="payment in receipt.paymentDetail"
        :key="payment.rowKey"
      >
        <div class="payment-details">
          <p class="payment-type" v-if="payment.type">{{ payment.type }}</p>
          <p class="payment-detail" v-if="payment.detail">
            {{ payment.detail }}
          </p>
        </div>
        <p class="payment-amount">£{{ payment.amount }}</p>
      </div>
    </div>
    <div class="return-items">
      <button
        class="returns-policy-button"
        @click="openLink(receipt.receiptDetail.returnPolicy)"
      >
        Returns policy
      </button>
      <button class="return-items-button" @click="visible = true">
        Return items
      </button>
    </div>
    <Dialog modal dismissableMask v-model:visible="visible" id="return-items">
      <template #header>
        <div id="modal-header" class="modal-header">
          <p class="modal-title">Returns QR Code</p>
        </div>
      </template>
      <div
        id="modal-content"
        class="centered returns-barcode modal-content"
        @click="showBarcodeValue = !showBarcodeValue"
      >
        <p>Show this code at checkout to return your items</p>
        <vue-barcode
          :value="receipt.receiptDetail.returnBarcode"
          :options="{ displayValue: showBarcodeValue }"
        ></vue-barcode>
      </div>
      <template #footer>
        <button class="primary-button close-modal" @click="visible = false">
          Close
        </button>
      </template>
    </Dialog>
  </div>
</template>
