import { createRouter, createWebHistory } from "vue-router";
import Receipt from "../pages/Receipt.vue";
import Error from "../pages/Error.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Receipt",
      component: Receipt,
      alias: "/receipt",
    },
    {
      path: "/error",
      name: "Error",
      component: Error,
    },
  ],
});

export default router;
