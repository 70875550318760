import { createStore } from "vuex";
import general from "./modules/general";
import receipt from "./modules/receipt";
import loyalty from "./modules/loyalty";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    general,
    receipt,
    loyalty,
  },
  strict: debug,
});
