<script setup>
import { computed } from "vue";
import brandLogo from "@/assets/logo.png";
import slipLogo from "@/assets/slip_logo.png";

const props = defineProps({
  variant: { type: String, required: true, default: "primary" },
  logo: { type: String, required: true, default: "slipLogo" },
});

const logoSrc = computed(() => {
  switch (props.logo) {
    case "brandLogo":
      return brandLogo;
    case "slipLogo":
      return slipLogo;
    default:
      return slipLogo;
  }
});
</script>

<template>
  <div :class="['container', variant]">
    <p class="tertiary-text logo-text" v-if="variant === 'secondary'">
      Powered by
    </p>
    <img :class="['logo', props.variant]" :src="logoSrc" />
  </div>
</template>

<style scoped>
@import "../styles/components/logo.css";
</style>
