// initial state
const state = () => ({
    loyalty: null,
})

// getters
const getters = {
    loyalty: (state) => {
        return state.loyalty
    },
}

// actions
const actions = {
    setLoyalty ({ commit }, loyalty) {
        commit('setLoyalty', loyalty)
    },
}

// mutations
const mutations = {
    setLoyalty (state, loyalty) {
        state.loyalty = loyalty;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}