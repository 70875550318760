<script setup>
import store from "@/store";
import Container from "@/components/Container.vue";
const error = store.getters["general/error"];
</script>

<template>
  <div>
    <Container>
      <h3>Something has gone wrong</h3>
      <p class="small-text">{{ error }}</p>
    </Container>
  </div>
</template>

<style scoped>
@import "../styles/pages/error-page.css";
</style>
