import { createApp } from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import VueBarcode from "@chenfengyuan/vue-barcode";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import * as Sentry from "@sentry/vue";
import "primevue/resources/themes/aura-light-green/theme.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://63b59ea4872aba84b17b16a1394b0697@o4506671320989696.ingest.us.sentry.io/4507061532950529",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
});

app.use(PrimeVue);
app.use(router);
app.use(store);
app.component(VueBarcode.name, VueBarcode);
app.component("Dialog", Dialog);

app.mount("#app");
