// initial state
const state = () => ({
    receipt: null,
})

// getters
const getters = {
    receipt: (state) => {
        return state.receipt
    },
}

// actions
const actions = {
    setReceipt ({ commit }, receipt) {
        commit('setReceipt', receipt)
    },
}

// mutations
const mutations = {
    setReceipt (state, receipt) {
        state.receipt = receipt;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}